.pageContainer {
  padding-top: 1em;
  :global .ui.dimmer {
    position: fixed !important;
  }
}
.recipeContainer {
  max-width: 723px;
  margin: 0 auto;
  margin-bottom: 1em;
  padding: 0 2em;
  background: white;
  border-radius: 5px;
}
