.macroSlider {
  background: #ffce07;
  padding: 1.2em;
  border-radius: 1em;
  margin-bottom: 1em;
  & span:global.MuiSlider-mark:nth-child(11) {
    background-color: #21ba45 !important;
  }
  :global .MuiSlider-valueLabel {
    left: auto !important;
  }
  :global .MuiSlider-track {
    border-radius: 10px 0px 0px 10px;
    height: 20px;
  }
  :global .MuiSlider-rail {
    height: 20px;
    border-radius: 10px;
  }

  :global .MuiSlider-mark {
    width: 4px;
    height: 32px;
    position: absolute;
    border-radius: 1px;
    background-color: #ad8104;
    top: 7px;
    margin-left: -2px;
  }
  :global .MuiSlider-markLabel {
    top: 50px;
    font-size: 1.1em;
    font-family: inherit;
  }

  :global .MuiSlider-thumb {
    height: 40px;
    width: 40px;
    margin-left: -20px;
    margin-top: -11px;
    border-radius: 50%;

    background: linear-gradient(180deg, #47c73c 0%, #7af56f 100%);
    box-shadow: 0px 8px 12px 0px #8397ac 44.06%;

    box-shadow: 0px 2px 4px 0px #435261 58.88%;

    box-shadow: 0px 0px 6px 0px #6a7c8e 38.35% inset;
  }

  //   :global .PrivateValueLabel-offset-3 {
  //     // margin-left: 28px;
  //   }

  :global .MuiSlider-root {
    color: #ad8100;
    font-size: 0.7em;
  }
}
