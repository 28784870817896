@import "../../variables.scss";

.weekContainer {
  display: flex;
  justify-content: space-between;
  max-width: 550px;
  margin: auto;
  h2 {
    margin: 0;
    line-height: 2.5rem;
  }
  :global .ui.button {
    background: transparent;
    font-size: 22px;
    padding: 10px;
    padding-top: 5px;
    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
    }
  }
}

.tabMenuItem {
  max-height: 50px;
  margin-bottom: 1em;
  padding: 0 1em;
  img {
    height: 50px;
  }
  span {
    display: inline-block;
    margin-top: 0.5em;
  }
}

.lockedOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  color: #ffffff96;
  z-index: 999;
}

.weekContent {
  margin-top: 1em;
  background: white;
  padding: 0.5em;
  border-radius: 15px;
}

.placeholder {
  align-items: center;
  justify-content: space-around;
  display: flex;
}
.placeholder {
  align-items: center;
  justify-content: space-around;
  display: flex;
}
.placeholder.meal {
  background: #f3f1fe !important;
  color: #403481;
}
.placeholder.meal {
  background: #f0f9e4 !important;
  color: #88b058;
}
.placeholder.workout {
  color: white !important;
}

.placeholderIcon {
  font-size: 3em;
}

.PlannerWrapper :global .desktopOnly {
  flex: 1;
  @media (max-width: 991px) {
    display: none;
  }
}

.PlannerWrapper :global .mobileOnly {
  flex: 1;
  @media (min-width: 992px) {
    display: none;
  }
}
.PlannerWrapper {
  @media print {
    padding: 10mm;
  }
  @media print {
    .dayHeader {
      color: black;
    }
    .Toolbar {
      display: none !important;
    }
    .weekContainer {
      margin-bottom: -10mm;
      :global .ui.button {
        display: none !important;
      }
    }
    h3 {
      font-size: 1.8rem;
    }
    .dropBox {
      font-size: 1.2em;
      overflow: visible;
      margin: 2mm;
      margin-bottom: 5mm;
    }
    .addBox {
      display: none !important;
    }
    .recipeCard {
      margin: 0 2em;
      font-size: 0.8rem;
      padding: 0.2em;
      box-shadow: 3px 3px 0px black inset;
      &:global.reduced {
        box-shadow: 3px 3px 0px blue inset !important;
      }
      &:global.refuel {
        box-shadow: 3px 3px 0px green inset !important;
      }
      &:global.snack {
        border-radius: 10em;
      }
      .recipeImage {
        opacity: 0.2;
        box-shadow: initial;
      }
      svg {
        opacity: 0.01;
      }
      .lockedOverlay {
        display: none !important;
      }
      span {
        font-size: 1.3rem;
        background: transparent;
        color: black;
        padding: 0;
        bottom: auto;
        top: 0;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0.5em;
        height: 100%;
      }
    }
  }
}

.leftoverNumber {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  margin-right: -0.5em !important;
  margin-bottom: -0.2em !important;
  user-select: none;
}
.clipBoard {
  @media (min-width: 992px) {
    background: white;
  }
  margin: 0.5em;
  border: 2px dashed #ccc;
  border-radius: 0.3em;
  padding: 1em;
  margin-top: 2em;
  min-height: 85px;

  .questionIcon {
    padding-left: 0.5em;
    cursor: pointer;
    &:hover {
      color: orange;
    }
  }

  .clipBoardTitle {
    margin-top: -2em !important;
    @media (min-width: 992px) {
      background: white;
    }
    @media (max-width: 991px) {
      background: $bodyBackground;
    }
    border-radius: 15px;
    padding: 0.2em 0.5em;

    color: #787878;
    position: absolute;
    display: flex;
    .trashIcon {
      padding: 0 0.5em;
    }
  }
  > .Items {
    display: flex;
    flex-wrap: wrap;
  }
}

.addBox:hover,
.addDropBox:hover {
  color: orange;
  border-color: orange;
  cursor: pointer;
}

.dayHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1em 0;
  color: #787878;
  > div {
    display: flex;
  }
  .Toolbar {
    display: flex;
    cursor: pointer;

    > div {
      padding: 0.3em 0.3em;
      margin: 0.3em;
      &:hover {
        color: orange;
      }
    }
  }
  h3 {
    margin: 0;
    line-height: 1.7em;
  }
  .workoutIcon {
    font-size: 2em;
    padding-left: 0.2em;
  }
}

.diaryContainer {
  margin: 0.5em;
}

.recipeContainer {
  display: flex;
  &:global.mini {
    @media only screen and (max-width: $tabletBreakpoint) {
      :local .recipeCard {
        display: flex;
        h3 {
          font-size: 1rem;
          align-self: center;
        }
      }
    }
  }
}

.addBox {
  border: 2px dashed #ccc;

  //height: 98px;
  width: 98px;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: #ccc;
  padding-top: 0.3em;
  margin-bottom: 0.1em;
  margin-right: 0.3em;
}

.nutritionOverview {
  display: flex;
  justify-content: space-between;
  margin: auto;

  .calories {
    flex: 1 1;
    max-width: 6em;
    background: #47c73c;
    padding: 15px;
    border-radius: 16px;
    font-size: 2rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .daily {
    margin: 1em auto;
    height: 60px;
    margin-top: 0;
    // background: skyblue;
    flex: 1 1;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 130px;
    padding-top: 0.3em;

    .target {
      position: absolute;
      bottom: 0;
      height: 70%;
      width: 100%;
      border-top: 2px dotted #787878;
    }

    .day {
      display: flex;
      align-items: flex-end;
      height: 100%;
      background: #fff1b5;
      border-radius: 5px;
      overflow: hidden;
      > div {
        background: #ffd835;
        width: 10px;
        border-radius: 5px;
        transition: height 2s;
        span {
          position: absolute;
          bottom: 0;
          margin-bottom: -20px;
        }
      }
    }
  }
}

.addDropBox {
  border: 2px dashed #ccc;

  min-height: 1em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: #ccc;
  padding-top: 0.3em;
  width: 100%;
  flex-direction: column;
}
.customCard {
  background-color: #ffbe00;
  &:global.treat {
    background-color: $treat;
    color: darken($treat, 60%);
  }
  &:global.eatout {
    background-color: $eatout;
    color: darken($eatout, 60%);
  }
  .customOverlay {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  height: 10em;
  border-radius: 1em;

  background-size: cover !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.recipeCard {
  margin: 0 0.4em;

  width: 10em;
  max-width: 10em;
  min-width: 10em;
  position: relative;
  height: 10em;
  overflow: visible;
  border-radius: 1em;
  margin-bottom: 1em;

  &:global.refuel {
    :local .recipeImage {
      box-shadow: 3px 3px 0px green inset;
    }
  }
  &:global.reduced {
    :local .recipeImage {
      box-shadow: 3px 3px 0px blue inset;
    }
  }
  &:global.snack {
    :local .recipeImage {
      border-radius: 10em;
    }
    span {
      border-bottom-left-radius: 10em;
      border-bottom-right-radius: 10em;
      justify-content: center;
      text-align: center;
      padding-bottom: 1em;
    }
  }

  .recipeImage {
    height: 10em;
    border-radius: 1em;
    background-size: cover !important;
  }
  span {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    pointer-events: none;
    user-select: none;
    font-size: 1.1em;
    display: flex;
    line-height: 1.2em;
    position: absolute;
    bottom: 0;
    padding: 0.5em;
    color: white;
    font-weight: 500;
    width: 100%;
    min-height: 4em;
    align-items: flex-end;
    padding-top: 4em;
    background: linear-gradient(
      transparent 0%,
      #0000000f 25%,
      #00000052 50%,
      #000000e3 100%
    );
  }
}

.dropBox {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

@media (min-width: 992px) {
  .nutritionOverview {
    .calories {
      max-width: none;
      margin: 0;
      min-height: 65px;
    }
    .daily {
      max-width: none;
      margin: 1em;
    }
  }
}
.dropBox > div {
  font-size: 0.65em;
}

.clipBoard {
  .Items {
    font-size: 0.5em;
  }
}

.Icon {
  font-size: 2rem;
}
.TabContainer :global .item {
  font-weight: bold;
  font-size: 1rem;
}

.shoppingList {
  list-style-type: none;
  padding: 0;
  div {
    margin-right: 2em;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2em;
    font-size: 2em;
  }
}
.shoppingModalActions {
  padding: 0 !important;
  margin: 0;
  display: flex;
  button {
    flex: 1;
    margin: 0.5em !important;
  }
}

.Modal {
  min-height: 100vh;
  width: 100vw;
  z-index: 2000;
  background: white;

  position: absolute;
  padding-bottom: 80px;
  top: 0;
  .ModalContainer {
    height: 100%;
    padding-bottom: 100px;
    background: white;
    width: 100%;
    padding: 1em;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .Footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 115px;
    background: linear-gradient(
      to top,
      white 31.06%,
      rgba(255, 255, 255, 0.23) 65%,
      rgba(255, 255, 255, 0)
    );
    padding: 4em 1em;
    margin: 0;
    left: 0;
    text-align: center;
    margin-bottom: -5px;
    z-index: 10;
  }
}

.workoutOverlay {
  height: 100%;
  width: 100%;
  background: #0073ef82;
  /* border: solid 4px blue; */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  background-size: cover !important;
  transform: scale(2, 2);
  opacity: 0.5;
}

.workoutImage {
  overflow: hidden;
  border: solid 4px blue;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: blue;
  background-blend-mode: hard-light;
}

.calorieFigure {
  padding-left: 0.5em;
  margin-top: 0.2em;

  .calorieBox {
    display: flex;
  }
  .calorieUnit {
    font-size: 0.5em;
  }
  .calorieCaption {
    font-size: 0.5em;
  }
}
@media (max-width: 480px) {
  .addBox {
    max-width: 2em;
    transition: all 0.3s ease-in-out;
  }
  .recipeCard,
  .customCard {
    width: 7em;
    max-width: 7em;
    min-width: 7em;

    height: 7em;

    .recipeImage {
      height: 7em;
    }
    span {
      padding-top: 2em;
    }
  }
}

.advancedNutritionTable {
  tr {
    padding: 0 !important;
  }
  th {
    background: $green !important;
    color: white !important;
    padding: 0.5em 1em;
  }
}

.advancedNutritionButton {
  cursor: pointer;
  text-align: center;
  &:hover {
    color: $orange;
  }
}

.copyWeek {
  width: 100%;
  :global .button:first-child {
    border-top-left-radius: 2em !important;
    border-bottom-left-radius: 2em !important;
  }
  :global .button:last-child {
    border-top-right-radius: 2em !important;
    border-bottom-right-radius: 2em !important;
  }

  :global .ui.labeled.icon.buttons .button,
  :global .ui.labeled.icon.button {
    padding-left: 2em !important;
  }
}
