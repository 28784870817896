.Container {
  border: 0.3em solid #47c73c;
  box-sizing: border-box;
  border-radius: 1.4em;
  padding: 1em;
  margin: 1em 0;
}

.Header {
  background: #47c73c;
  padding: 0.3em;
  color: white;
  display: inline-block;
  margin: 0.5em 0;
  margin-top: -1em;
  border-radius: 5px;
}

.Content {
  /* Get any recipe tailored to your needs! Take our nutrition analysis quiz and we will personalize your recipes based on your own data! */
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 1.5em;
  /* or 131% */
  p {
    line-height: 1.5em;
  }
  em {
    color: #ffce07;
    font-style: normal;
    font-weight: 600;
  }
  color: #000000;
}
.Footer {
  margin-top: 0.5em;
  text-align: right;
}
