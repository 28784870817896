@import "../variables.scss";

div.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
div.container {
  background: rgba(245, 249, 241, 1);
  @media print {
    background: transparent;
  }
}

.TermsModal {
  z-index: 1000001 !important;
  h1 {
    font-size: 1rem;
  }
  h2 {
    font-size: 1rem;
  }
  h3 {
    font-size: 0.9rem;
  }
  h4 {
    font-size: 0.9rem;
  }
}

.content {
  flex: 1 0;
  padding-bottom: 56px;
  padding-top: $barHeightMobile;
  height: 100%;
  > div {
    padding-bottom: 20px;
  }
  @media screen and (min-width: $computerBreakpoint) {
    padding-top: $barHeightComputer;
  }
  @media print {
    padding-top: $barHeightMobile;
  }
}
.topMenu:global.ui.secondary.menu {
  // font-family: $alternativeFont;
  :global .item:hover {
    background-color: lighten($green, 30%) !important;
  }
  :global .active.item {
    font-weight: bold;
    background-color: lighten($green, 10%);
    color: white;
  }
  font-size: 1.2rem;
  :global .item {
    font-weight: bold !important;
    padding: 1.4rem 2rem;
  }
}

@media screen and (min-width: 992px) {
  .content {
    padding-bottom: 0;
  }
}

.bottomNav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);

  .item:before {
    width: 0 !important;
  }
  a {
    opacity: 0.8;
  }
  .bottomNavButton {
    height: 35px;
    width: 3em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .home {
    .bottomNavButton {
      background-image: url(/img/ui/home.svg);
    }
    &:global.active {
      :local .bottomNavButton {
        background-image: url(/img/ui/home-active.svg);
      }
    }
  }
  .planner {
    .bottomNavButton {
      background-image: url(/img/ui/calendar.svg);
    }
    &:global.active {
      :local .bottomNavButton {
        background-image: url(/img/ui/calendar-active.svg);
      }
    }
  }

  .recipes {
    .bottomNavButton {
      background-image: url(/img/ui/recipes.svg);
      background-position-y: 2px;
    }
    &:global.active {
      :local .bottomNavButton {
        background-image: url(/img/ui/recipes-active.svg);
      }
    }
  }

  .shopping {
    .bottomNavButton {
      background-image: url(/img/ui/shopping.svg);
    }
    &:global.active {
      :local .bottomNavButton {
        background-image: url(/img/ui/shopping-active.svg);
      }
    }
  }
  .profile {
    .bottomNavButton {
      background-image: url(/img/ui/profile.svg);
    }
    &:global.active {
      :local .bottomNavButton {
        background-image: url(/img/ui/profile-active.svg);
      }
    }
  }
}
.backButton {
  font-size: 2em;
  margin: 0.3em;
  color: green;
}

.statusLeft {
  display: flex;
  align-items: center;
  @media print {
    .logoText {
      width: 70mm !important;
    }
  }
}

.statusBar {
  height: $barHeightMobile;
  z-index: 999;
  position: absolute;
  padding: 0.2rem;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #97979745;
  background: linear-gradient(0deg, white, #efefef82);
  .logo {
    padding: 0.5em;
    height: 100%;
    height: $barHeightMobile;
    img {
      max-height: 100%;
      // filter: blur(6px);
    }
  }
  @media print {
    background: transparent;
    display: flex;
    align-items: center;
    padding: 1em;
  }

  @media screen and (min-width: $computerBreakpoint) {
    display: flex;
    align-items: center;
    height: $barHeightComputer;
    background: white;
  }
  @media print {
    height: $barHeightMobile;
  }
}

:global .raf-dropdown-panel {
  margin-left: 35px !important;
  background: #ffffff !important;
  .raf-feed-placeholder {
    background: white;
  }
  .notifyPlaceholder {
    margin: 1em;
  }
}

:local .bubble {
  border-radius: 50%;
  display: flex;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  pointer-events: none;
  height: 100px;
  position: absolute;
  width: 100px;
  padding: 1em;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 100%;
  animation: animateBubble 4s linear forwards, bubbleText 4s linear forwards,
    sideWays 2s ease-in-out infinite alternate;
}

.bubble:after {
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  border-radius: 50%;
  box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  content: "";
  height: 180px;
  left: 10px;
  position: absolute;
  width: 180px;
  animation: bubbleShadow 4s linear forwards;
}

@keyframes bubbleShadow {
  0% {
  }
  80% {
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 70%
    );
  }
  100% {
    height: 10px;
    width: 10px;
    background: rgb(172, 172, 172);
  }
}

@keyframes animateBubble {
  0% {
  }
  100% {
    top: 0;
    left: calc(100%-50px);
    margin-top: -200px;
    height: 10px;
    width: 10px;
  }
}
@keyframes bubbleText {
  0% {
  }
  100% {
    font-size: 0.4em;
  }
}

@keyframes sideWays {
  0% {
    margin-left: -50px;
  }
  100% {
    margin-left: 0px;
  }
}

.xpCaption {
  padding: 0.2em;
}
.xpIcon {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  img {
    height: 100%;
    width: auto;
  }
}

.Stats {
  background: white;
  /* padding: 0.4em 0.8em; */
  margin: 0.5em 0.4em;
  display: flex;
  height: 2em;
  width: 8.5em;
  border-radius: 15px;
  box-shadow: 0px 0px 7px 0px #c3c3c3;
  overflow: hidden;
  margin-left: auto;
}

.Streak {
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: bold;
  justify-content: flex-end;
  margin-right: 0.5em;
  .StreakIcon {
    margin: 0 0.2em;
  }
}

.Total {
  background: $green;

  border-radius: 15px;
  font-weight: bold;
  color: white;
  /* flex: 1 1; */
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 1em;
  min-width: 5em;

  .Icon {
    margin-right: 0.5em;
  }
}

.Rocket {
  pointer-events: none;

  position: fixed;
  transform: scale(1);

  bottom: 0vh;
  img {
    transform: scale(5);
    animation: starImage 5s ease-in forwards;
  }

  animation: star 3s ease-in-out forwards, star2 4s linear forwards,
    star4 3s ease-in-out forwards;
  width: 200px;
  :global .caption {
    font-size: 1.7em;
    margin: 0;
    /* margin-top: -12em; */
    transform: rotate(-2deg);
    /* background: black; */
    padding: 0;
    line-height: 2em;
    color: black;
    position: absolute;
    padding: 1em;
    left: -2.4em;
    font-family: inherit;
    font-weight: bold;
    top: -0em;
    line-height: 1em;
    padding: 0;
    display: inline-flex;
    //  animation: textFlash 2s ease-in-out infinite alternate;
  }
  :global .score {
    position: absolute;
    top: 1em;
    left: -0.3em;
    font-size: 3em;
    transform: rotate(-2deg);
    font-weight: 900;
    // animation: textFlash 2s ease-in-out infinite alternate;
  }
}

@keyframes textFlash {
  0% {
    color: black;
  }
  100% {
    color: #ffa500;
  }
}

@keyframes star {
  0% {
    top: 100vh;
  }
  50% {
    top: 20vh;
  }
  100% {
    top: -40vh;
    margin-top: -40vh;
  }
}

@keyframes star2 {
  0% {
    transform-origin: center center;
    transform: rotate(45deg);
    transform: scale(0.1);
  }
  50% {
    transform-origin: center center;
    transform: scale(1) rotate(0);
  }
  100% {
    transform-origin: center center;
    transform: scale(0) rotate(720deg);
  }
}

@keyframes star3 {
  0% {
  }
  50% {
  }
}

@keyframes star4 {
  0% {
    left: 0vw;
  }
  50% {
    left: 40vw;
    margin-top: 00vh;
  }
  100% {
    left: -20vw;
    margin-top: -50vh;
  }
}

.AstroWater {
  height: 120px;
  margin-top: 120px;
  position: absolute;
  right: -200px;
  width: 300px;
  img {
    height: 100%;
  }
  .astro {
    position: absolute;
    top: 0;
    left: 0;
    animation: waterMan 1s linear infinite alternate;
  }
  img.duck {
    height: 50% !important;
  }
  .duck {
    position: absolute;
    top: -50px;
    left: 100px;
    animation: duck 3s ease-in-out infinite linear;
  }
  animation: waterManContainer 8s forwards linear;
}

@keyframes duck {
  0% {
    margin-left: 30px;
  }
  100% {
    margin-left: -30px;
  }
}
@keyframes waterMan {
  0% {
    margin-top: 10px;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes waterManContainer {
  0% {
    right: -400px;
  }
  100% {
    right: 100vw;
  }
}

:global .reactour__helper {
  border-radius: 1em;
}
