@import "../../../variables.scss";

.MacroList {
  display: flex;
  flex-wrap: wrap;
  :global .item {
    flex: 1;
    flex-basis: 50%;
    @media only screen and (min-width: $computerBreakpoint) {
      flex-basis: 25%;
    }
  }
}

.title {
  text-transform: capitalize;
}

.newMacro {
  td {
    padding: 0.5em 0.2em;
    text-align: center;
  }
  input {
    width: 3em !important;
    padding: 0.5em 0.2em !important;
  }
}
