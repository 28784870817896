@import "../../variables.scss";

.homeContainer :global .ui.segment {
  :local .Container {
    border-radius: 1.42857143rem;
  }
}

.homeContainer {
  background: #eef7ee;
}
.Container {
  padding: 0 1em;
  position: relative;
  height: 100%;
  display: flex;

  h1 {
    margin: 0 !important;
  }
}

.snacks {
  display: flex;
  :global .ui.message {
    margin: 0;
    flex: 1;
    h2 {
      font-size: 1em;
    }
    &:first-child {
      margin-right: 0.5em;
    }
  }
}
.DayWrapper {
  :global .ui.message {
    opacity: 0.75;
    h2 {
      width: 100%;
    }
    &:global.workout {
      margin: 0;
      font-size: 0.7em;
      margin-bottom: 1em;
      :local .image {
        display: flex;
      }
    }
    &:global.snack {
      font-size: 0.7em;
      margin: 0em 1em;
      margin-bottom: 1em;
      display: flex;
      align-items: center;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    cursor: pointer;
    h2 {
      margin: 0.7em 0;
      font-size: 1.3em;
    }
  }
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.DayContainer {
  user-select: none;

  .ActiveDay {
    position: absolute;
    height: 100%;
    background: #ffea47;
    width: 13%;
    z-index: -1;
    border-radius: 15px;
    transition: 0.3s ease-in all;
  }
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  user-select: none;
  margin: 0 -0.5em;
  margin-top: 0.3em;
  padding: 0.5em 0em;
  margin-bottom: 0.5em;

  .DayArrow {
    display: flex;
    flex-direction: column;
    flex: 0 1 4.5%;
    overflow: hidden;
  }
  .Days {
    display: flex;
    flex-direction: column;
    flex: 0 1 13%;

    border-radius: 20px;
    text-align: center;
    user-select: none;
    cursor: pointer;

    -webkit-touch-callout: none;
    :focus {
      outline: none; /* no outline - for most browsers */
      box-shadow: none; /* no box shadow - for some browsers or if you are using Bootstrap */
    }
    user-select: none;

    @media (hover: hover) {
      &:hover {
        color: lighten($green, 10%);
      }
    }
  }
  & :global .active {
    transition: 0.2s linear all 0.2s;
    color: $green;
  }

  .Name {
    font-weight: bold;
  }
  .Number {
    font-weight: bold;
  }
}

.Image {
  // background: #ccc;
  width: 10em;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: hidden;
  > div {
    height: 100% !important;
    cursor: pointer;
  }
}
:global .disabled {
  opacity: 0.75;
  cursor: not-allowed !important;
  :local .Options > div {
    opacity: 0.35 !important;

    cursor: not-allowed !important;
  }
}
.waterTrackerContainer:global.disabled {
  opacity: 0.35 !important;
  cursor: not-allowed !important;
}
.DaySegment {
  background: white;
  display: flex;
  min-height: 6em;
  border: solid 1px #ccc;
  border-radius: 20px;
  margin-bottom: 1em;
  overflow: hidden;
  box-shadow: #00000026 1px 2px 0px 1px;

  > div {
    // background: white;
    z-index: 1;
    overflow: hidden;
  }
}
.Options {
  flex-direction: column;
  width: 50px;

  > div:first-child {
    border-bottom: 1px solid #ccc;
  }
  > div {
    width: 100%;
    display: flex;
    border-left: 1px solid #ccc;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-weight: bold;
  }
  > div:last-child {
    border-bottom: none !important;
  }
}
.swap {
  font-size: 0.9em;
  font-weight: bold;
}
.Content {
  padding: 1em;
  flex: 1;
  display: flex;
  align-items: center;
  display: inline;
  margin-top: 0.5em;
  cursor: pointer;

  // background: white;
}

.Options {
  display: flex;

  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

  align-items: center;
}

.snacks > div {
  margin: 0em 1em;
  margin-bottom: 1em;
}
.snacks > div:nth-child(odd) {
  margin-left: 0;
}
.snacks > div:nth-child(even) {
  margin-right: 0;
}

.workoutWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  .workoutTopRow {
    display: flex;
    flex: 1;
    h3 {
      margin: 0;
    }
  }
}

.Snack {
  display: flex;
  align-items: stretch;
  width: 50%;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 0;
  overflow: hidden;
  background: white;
  box-shadow: #00000026 1px 2px 0px 1px;
  min-height: 4em;

  // > div {

  //   align-items: stretch;
  //   display: flex;
  //   background: white;
  //   z-index: 1;
  //   overflow: hidden;
  // }
  .Options {
    width: 57px;
  }
  .content {
    padding: 0.5em;
    width: 80%;
    display: flex;
    align-items: center;
    flex: 1;
    cursor: pointer;
    h5,
    h6 {
      margin: 0;
    }
  }
  // .Options {
  //   padding: 0;
  //   width: 50px;
  //   > div {
  //     display: flex;
  //     align-items: center;
  //     padding: 0;
  //     padding-right: 0.5em;
  //     min-width: 3em;
  //     justify-content: space-around;
  //   }
  //   .check {
  //     border-left: 1px solid #ccc;
  //     margin-right: 1em;

  //     i {
  //       margin-top: -0.3em;
  //     }
  //   }
  // }
}

.Options.checked {
  .check {
    background: $green;
    color: white;
    margin: 0;
  }
}

.placeholder {
  align-items: stretch;
  justify-content: space-around;
  display: flex;
  .Image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.placeholder.custom,
.placeholder.workout {
  .Content {
    padding: 0;
    display: flex;
    align-items: center;
  }
  background: #f3f1fe;

  align-items: normal;
  :local .Image {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  &:global.customCard {
    background: lighten(#ffbe00, 20%);
    color: darken(#ffbe00, 60%);
  }

  &:global.treat {
    background-color: $treat;
    color: darken($treat, 60%);
  }
  &:global.eatout {
    background-color: $eatout;
    color: darken($eatout, 60%);
  }
}

.placeholder.workout {
  background: lighten($workout, 50%);
  color: $workout;
  .Image {
    width: auto;

    padding: 1em 2em;
  }
}

.placeholder.meal {
  background: #f3f1fe;
  color: #403481;
  align-items: normal;
  :local .Image {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.placeholder.placeholderCard {
  background: white;
  color: #ccc;
  align-items: normal;
  :local .Image {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.placeholder.meal {
  background: #f0f9e4;
  color: #88b058;
}

.placeholderIcon {
  font-size: 2.4em;
  align-items: center;
  display: flex;
  transform: scale(1.3);
}
.check {
  transition: background-color 0.2s, height 0.5s, color 0.1s;
}
.recipe,
.Snack {
  .checked {
    background: $green;
    color: white;
  }
  @media (hover: hover) {
    .Options > div:hover {
      background: lighten($green, 20%);
      color: white;
      cursor: pointer;
    }
  }
}
.checked {
  .swap {
    display: none !important;
  }
}
.placeholder.workout {
  .checked .check {
    background: $workout;
    color: white;
    border-left: none;
  }
  @media (hover: hover) {
    .Options > div:hover {
      background: lighten($workout, 20%);
      color: white;
      cursor: pointer;
    }
  }
}

.meal {
  .checked {
    .check {
      background: darken($meal, 18%) !important;
      color: $meal;
    }
  }
  @media (hover: hover) {
    .Options > div:hover {
      background: lighten($meal, 20%);
      color: $meal;
      cursor: pointer;
    }
  }
}

:global .treat {
  :local .checked {
    .check {
      background: darken($treat, 60%) !important;
      color: $treat;
    }
  }
  @media (hover: hover) {
    :local .Options > div:hover {
      background: darken($treat, 5%) !important;
      color: darken($treat, 60%);
      cursor: pointer;
    }
  }
}
:global .eatout {
  :local .checked {
    .check {
      background: darken($eatout, 60%);
      color: $eatout;
    }
  }
  @media (hover: hover) {
    :local .Options > div:hover {
      background: darken($eatout, 5%);
      color: darken($eatout, 60%);
      cursor: pointer;
    }
  }
}

:global .customCard {
  :local .checked {
    .check {
      background: darken(#ffbe00, 5%);
      color: darken(#ffbe00, 60%);
    }
  }
  @media (hover: hover) {
    :local .Options > div:hover {
      background: darken(#ffbe00, 5%);
      color: darken(#ffbe00, 60%);
      cursor: pointer;
    }
  }
}

.waterTrackCurves {
  margin-left: -1em;
  margin-right: -1em;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 300px;
  background-size: cover !important;
  z-index: -1;
  mix-blend-mode: color-dodge;
  opacity: 0.9;
}

.Container {
  /* Rectangle */
  // height: 100px;
  // mix-blend-mode: color-burn;
  // opacity: 0.22;
  // filter: blur(89.9217px);
  // position: absolute;
  // bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  // background: linear-gradient(176.8deg, #ffffff 2.35%, #7ca4ffd4 97.35%);
  background: linear-gradient(176.8deg, #ffffff 2.35%, #16ca000f 97.35%);
}

.waterTrackerContainer {
  .image {
    margin-top: 1em;
  }
  .image img {
    width: 40px;
  }
}

.barContainer {
  width: 75%;
  margin: 1em;
}

.barCaption {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.2em;
  text-align: center;
}

.barBackground {
  width: 100%;
  height: 1em;
  background: #b9d2e8d6;
  border-radius: 2em;
  overflow: hidden;
  .barFill {
    height: 100%;
    background: linear-gradient(45deg, #00b2ff, #00ffa1);
    transition: width 0.5s ease-in-out;
  }
}

.plusButton {
  /* Oval */

  user-select: none;
  background: linear-gradient(180deg, #ffffff 0%, #a0c9fa 100%);
  box-shadow: 0px 4px 16px rgba(7, 59, 255, 0.3);
  box-shadow: 2px 2px 0px 2px #0000001f;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  font-size: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  /* padding-top: 0.1em; */
  color: #74b6cc;
  border: none;
  outline: none !important;
  font-weight: 400;
  &:active {
    box-shadow: 3px 4px 0px 1px #00000075;
    transform: translateY(2px);
  }
}
:global .disabled {
  :local .plusButton {
    &:active {
      box-shadow: 0px 4px 16px rgba(7, 59, 255, 0.3);
      transform: none;
    }
  }
}

.waterTrackerContainer {
  padding: 1em;
  padding-top: 0;
  padding-left: 0.3em;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  justify-content: space-between;
}

:global .swipeContainer {
  margin: 0 -1em;
  flex: 1;
  > div > div {
    padding: 0 1em;
  }
}

.swapModal {
  :global .scrolling.content {
    max-height: calc(96vh - 10em) !important;
  }
}

.ActivityFeed :global .label img {
  border-radius: 0 !important;
}

.dayPlan {
  display: flex;
  .image {
    width: 3em;
  }
  .text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 1em;
  }
}

@media screen and (max-width: $computerBreakpoint) {
  .macroHeader {
    display: none !important;
  }
}
