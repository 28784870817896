.advanced {
  ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      padding: 0.5em;
      display: inline-block;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      label {
        display: block;
      }
    }
  }
}
