@import "../../../variables.scss";

ul.Macros {
  flex: 1;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    width: 25%;

    padding: 0 1.2em;
  }
  span {
    font-size: 0.8em;
    text-align: center;
    display: block;
    text-transform: capitalize;
    margin: 0 -1em;
    margin-top: 0.3em;
  }
}
.VerticalCard {
  background: white;
  flex: 1;
  border: solid #ccc 1px;
  border-radius: 25px;
  margin: 1em 0.5em !important;
  position: relative;
  overflow: hidden;
  height: 200px;
  user-select: none;
  cursor: pointer;
  &:hover {
    box-shadow: #000000cc 1px 1px 6px;
  }
  display: flex;
  flex-direction: column;
  .Caption {
    min-height: 50%;
    width: 100%;
    color: #ccc;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5em;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.251) 20.06%,
      rgba(255, 255, 255, 0.1) 87%,
      rgba(255, 255, 255, 0)
    );
    padding-top: 2em;
    bottom: 0;
    position: absolute;
    font-size: 1.3em;
    h5 {
      margin: 0;
      color: #e0e0e0;
      padding-left: 0.5em;
      &:nth-child(1) {
        padding-top: 0.3em;
      }
    }
    h2 {
      font-size: 1.1em;
      padding: 0.5em;
      margin: 0;
      color: white;
      padding-bottom: 0.4em;
    }
  }
}
.webCaption {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (max-width: $tabletBreakpoint) {
    display: block;
  }
}

.Row {
  display: flex;
  margin: 1em;
}

.HeaderImage {
  height: 100%;
  width: 100%;
  background-size: cover !important;
  position: absolute;
  z-index: 0;
}

.ImportButton {
  margin: 1em 0;
}

ul.viewTab {
  list-style: none;
  display: flex;
  margin: 0;
  font-size: 1.2em;
  padding: 0;
  padding: 0;
  border: #ccc solid 1px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  li {
    padding: 0.3em;
    flex: 1;
    text-align: center;
    &:hover {
      background: #ccc;
    }
    &:first-child {
      border-right: #ccc solid 1px;
    }
    &.active {
      font-weight: 600;
    }
  }
}

:global .slick-prev:before,
:global .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: black !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Row :global .slick-list > div {
  margin-left: 0;
}

.viewContainer {
  :global .ui.stackable.grid div.column {
    padding: 0em 1em !important;
  }
}

.topicCard {
  height: 200px;
  @media only screen and (max-width: $tabletBreakpoint) {
    height: 120px;
    h2 {
      font-size: 1.2em;
    }
  }
  h2 {
    z-index: 2;
  }
  h2::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  background: #ffce02;
  padding: 1em;
  border-radius: 25px;
  width: 100%;
  align-content: flex-end;
  display: flex;
  position: relative;
  overflow: hidden;
  user-select: none;
  box-shadow: #0000002b 2px 2px 8px;
  cursor: pointer;
  &:hover {
    box-shadow: #000000ba 2px 2px 8px;
  }

  h2 {
    margin-top: auto !important;

    color: white;
  }
  background-size: cover !important;
  .follow {
    position: absolute;
    top: 0;
    left: 0;
    background: $green;
    padding: 0.5em 1em;
    font-weight: 600;
    color: white;
    border-bottom-right-radius: 25px;
    z-index: 3;

    &:hover {
      background: #f2711c;
    }
  }
}
.follow.following {
  background: #f2711c;
  &:hover {
    background: $green;
  }
}

.content {
  flex: 1;
  overflow: hidden;
  position: relative;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.footer {
  padding: 0.4em 1em;
}

.userImage {
  display: inline-block;
}
.sharedText {
  display: inline-block;
  font-size: 1.2em;
  letter-spacing: 0.01em;
  color: grey;
  margin-left: 0.5em;
  span {
    color: $green;
    font-weight: bold;
  }
}
