@import "../../../variables.scss";

.Stats {
  &:global.mini {
    font-size: 0.7em;
  }
  &:global.ui.segment {
    border-radius: 15px;
    color: white;
    font-family: $secondaryFont !important;
    padding: 1em;
  }
  :global .ui.statistics .statistic {
    flex: 1;
    min-width: auto;
    padding: 0.5em;
    &.calories {
      border-right: 1px solid white;
    }
    > .label,
    > .value {
      font-family: $secondaryFont !important;
      color: white;
      font-size: 2em !important;
      text-transform: none;
    }
    > .label {
      font-weight: 300 !important;
      font-size: 1em !important;
      text-transform: capitalize;
      margin-bottom: 0.8em;
    }
  }
}
.Ingredient {
  text-transform: capitalize;
}
.Table {
  font-size: 0.7em !important;
}
