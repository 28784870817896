.Container :global .recipeView {
  max-width: 400px !important;
  padding: 1em;
}

.alwaysReduce {
  display: flex;
  margin-top: 1em;
  justify-content: space-between;
  font-size: 0.95em;
}
.Container :global .column {
  padding: 0 !important;
  .recipeView {
    .container {
      margin: 0 !important;
    }
    .segment {
      padding: 0 !important;
    }
  }
}
