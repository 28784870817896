@import "../../variables.scss";

.recipeContainer {
  display: flex;
  :global .ui.grid {
    flex-grow: 1;
  }
  .imageColumn {
    display: flex !important;
  }

  .imageIconPlaceholder {
    flex: 1;
    font-size: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5em;
    background: rgb(226, 226, 226);
    border-radius: 15px;
    margin: -1rem;
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0cm;
    color: white;
    i {
      color: #c7c7c7;
    }
  }
  .gridWrapper {
    display: flex;
    .imageColumn {
      display: flex;
    }
    :global .ui.grid {
      flex-grow: 1;
    }
    :global .ui.placeholder.segment {
      min-height: 60px !important;
      flex-grow: 1;
    }

    &:global.mini {
      height: 60px;

      .ui.header {
        font-size: 1em !important;
      }
      .ui.grid {
        flex-grow: 1;
        .column {
          padding: 0.5em;
        }
      }
      :local .recipeCard {
        padding: 0.2em !important;
      }
      :local .recipeCardImage {
        margin: -0.5em 0 -0.5em -0.5em !important;
      }
      :local .recipeCardImage {
        background-size: cover !important;
        height: calc(100%);
        //   width: calc(100% + 2em);
        min-height: 60px;
      }

      :local .recipeCardBody {
        display: flex !important;
        align-items: center !important;
        font-size: 0.8em !important;
        padding: 0.2em !important;
      }
    }
    :global .ui.placeholder.segment {
      // height: calc(100% + 2em);
      // width: calc(100% + 2em);
      margin-left: -1em;
      margin-top: -1em;
      margin-bottom: -1em;
      margin-right: 1e;
      min-height: 100px;

      i {
        height: auto !important;
      }
      .ui.icon.header {
        margin: 0 !important;
        color: #ccc;
      }
    }
  }
}

.Card {
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: #efeded9c !important;
  }
  &:global.selected {
    background: #d1ffd1 !important;
    box-shadow: 0px 1px 3px 0px #55ff52, 0px 0px 0px 1px #37bd00 !important;
  }
  &:global.disabled {
    opacity: 0.3;
  }
}

.Popout {
  background: white !important;

  width: calc(100vw - 2em) !important;
  height: 95vw !important;
  // opacity: 1;
  z-index: 1000 !important;
  margin-top: 80px !important;

  &:hover {
    background: white !important;
  }
  .recipeCardImage {
    margin-right: -1em;
    margin-bottom: 1em;
    height: 300px;
    transition: background-image 1s ease-in-out;
  }
}

.Card.Vertical {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  margin: 1em 0 !important;
  .recipeCardImage {
    margin-right: -1em;
  }
}

.recipeCard {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  display: flex !important;
  flex-flow: column;
  padding-left: 0em !important;
}
.recipeCardBody {
  flex: 1;
}

.recipeCardImage {
  background-size: cover !important;
  height: calc(100% + 2em);
  //   width: calc(100% + 2em);
  margin-left: -1em;
  margin-top: -1em;
  margin-bottom: -1em;
  margin-right: 0;
  min-height: 100px;
  box-sizing: border-box;
  display: block;
  flex: 1;
  position: relative;
  background-position: center !important;
  &:global.blur {
    filter: blur(3px);
    transition: "all 0.2s ease-in";
  }
  .recipeCardRealImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: center !important;
    transition: "opacity 0.2s ease-in";
    //margin-right: 1em;
  }
}

.filters {
  margin-top: -1em;
}

.SelectedImage {
  width: 100%;
  height: 100%;
  background-color: #000000ab;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  opacity: 0.9;
}

.mealCardPlaceholder {
  min-height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.3);
}

.Card.meal {
  :global .content {
    align-items: center;
    background-color: lighten($meal, 30%) !important;
    :global .column > div {
      font-size: 1em;
      align-items: center;
      display: flex;
    }
    :global .header {
      color: $meal;
      font-size: 1em !important;
    }
  }
}

.popout {
  &.gridWrapper {
    align-items: flex-start;
    overflow-y: auto;
  }
  top: 0;
  position: fixed;
  z-index: 999;
  width: 100%;

  left: 0;
  height: 100%;
  background: white;
  .recipeCardImage {
    height: 25rem;
  }
  h3 {
    font-size: 2em;
  }
}

@media only screen and (max-width: $tabletBreakpoint) {
  :local .recipeCardBody {
    display: flex;
    h3 {
      font-size: 1rem;
      align-self: center;
    }
  }
}
