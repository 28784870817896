@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

$secondaryFont: "Poppins", sans-serif;
$alternativeFont: "Montserrat", sans-serif;
$bodyBackground: #f5f9f1;
$green: #47c73c;
$lilac: #f3f1fe;
$orange: #fdc117;

$meal: #88b058;
$treat: #c6e5fc;
$eatout: #fef0e1;
$workout: #2185d0;
$custom: #afeca7;

$mobileBreakpoint: 320px;
$tabletBreakpoint: 768px;
$computerBreakpoint: 992px;
$largeMonitorBreakpoint: 1280px;
$widescreenMonitorBreakpoint: 1920px;

$barHeightMobile: 45px;
$barHeightComputer: 90px;
