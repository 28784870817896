@import "../../variables.scss";

.container {
  overflow-x: auto;
  position: relative;
  font-size: 1.5em;
  thead th:first-child {
    background: #f5f9f1 !important;
    position: sticky;
    left: 0;
  }
  tfoot td:first-child,
  tbody td:first-child {
    left: 0;
    position: sticky;
    background: $green !important;
    z-index: 2;
  }
}
.progressPics {
  display: flex;
  align-items: flex-start;
  > div {
    flex: 1;
    img {
      max-width: 50px;
      max-height: 50px;
    }
  }
}
.add {
  background: $orange;
  color: $green;
}

.newForm {
  label {
    min-width: 9em;
  }
}

.intro {
  > i {
    color: $orange;
  }
  @media screen and (max-width: $computerBreakpoint) {
    > i {
      display: none !important;
    }
  }
}

:global .MuiPickersToolbar-toolbar {
  background-color: $green !important;
}
:global .MuiPickersDay-daySelected {
  background-color: darken($green, 10%) !important;
}
:global .MuiButton-textPrimary {
  color: $green !important;
}
:global .MuiInput-underline:after {
  border-color: $green !important ;
}
