@import "../../variables.scss";

.stats {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1em -1em;
  .statsItem {
    flex: 0 50%;
    padding: 1em;
    text-align: center;
    border-right: 1px solid #ccc;

    border-top: 1px solid #ccc;
    :nth-child(even) {
      border-right: none;
    }
    :nth-child(odd) {
      border-left: none;
    }
  }
}
.container {
  display: flex;
  flex-direction: column !important;
  flex: 1;
}
.header {
  flex-basis: 1;
}
.name {
  margin: 0;
}
.date_joined {
  margin: 0;
  font-family: $alternativeFont;
  font-size: 0.9em;
  font-weight: 600;
  color: grey;
}

.cog {
  :global .dropdown.icon {
    display: none !important;
  }
  :global .item a {
    color: black;
  }
}
.statsItem {
  display: flex;
  align-items: center;

  > div:nth-child(2) {
    :global .ui.mini.statistic {
      > div {
        text-align: left;
        text-transform: capitalize;
      }
    }
  }
}
.edit {
  font-size: smaller;
  margin-left: 1em;
  color: orange;
  cursor: pointer;
}

.goal {
  color: $green;
  font-weight: bold;
}
.statImage {
  max-height: 50px;
  width: 2.5em;
  padding-right: 0.5em;
}
.statsItem {
  div {
    display: inline-block;
  }
}

.slider {
  margin-top: 2em;
  &:global.MuiSlider-root {
    color: #009837;
    .MuiSlider-rail {
      height: 1em;
      border-radius: 1em;
    }
    .MuiSlider-track {
      height: 1em;
      border-top-left-radius: 1em;
      border-bottom-left-radius: 1em;
    }
  }
}

.profileDropdown {
  :global .green {
    color: darken($green, 10%) !important;
  }
  &:global.ui.dropdown {
    .menu {
      .header {
        cursor: pointer;
        padding: 0.78571429rem 1.14285714rem !important;
        margin: 0 !important;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
          z-index: 13;
        }
      }
    }
  }
}
