@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap");
.Container {
  width: 100%;
  background: #47c73c;
  color: white;
  display: flex;
  padding: 0em;
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
  .Centered {
    align-self: center;
    text-align: center;
    width: 100%;

    padding-top: 3em;
  }
  :global .MuiSlider-track {
    color: green !important;
  }
  :global .MuiSlider-thumbColorPrimary {
    color: green !important;
  }
}

.StepHeader {
  font-family: "Manrope", sans-serif;

  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }

  font-size: 2.2em;
  line-height: 1.5em;
  padding: 0 0.5em;
}

.tdeePersonal {
  label {
    font-size: 1.4rem;
  }
}
:global #root :local .tdeePersonal {
  :global .column {
    padding: 0.5em !important;
  }
  :global .row {
    padding-bottom: 0 !important;
  }
}
.calorieTarget {
  font-family: "Baloo Da 2", "Helvetica Neue", Arial, Helvetica, sans-serif;

  font-size: 5em;
  text-align: center;
  width: 100%;
  background: transparent;
  border: transparent;
  color: white;
}
.giantButton:global.ui.button {
  background: white;
  margin: 1em auto;
  border-radius: 4em;
  box-shadow: 0px 2px 7px #0000008a;
  padding: 1.2em 2.2em;
  width: 290px;
  min-height: 70px;
  h2 {
    font-family: "Baloo Da 2";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    color: #47c73c;
    margin: 0;
  }
  h3 {
    font-family: "Baloo Da 2";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin: 0;
  }
}
.Button {
  margin: 1em auto;
}
.Button :global .button {
  background: white !important;
}
.Toggle > div {
  opacity: 0.5;
}
.Button .Active {
  opacity: 1;
}
.Radio :global .ui.button {
  border-radius: 1em !important;
  padding: 1.5em;
}
.StepButton:global.ui.button {
  border-radius: 2em !important;
  padding: 0.8em 2em;
  background: white !important;
  margin: 1em 0;
  margin-top: 2em;
}

.RadioActive {
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.422) !important;
}

.Close {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.5;
  }
}

.Container {
  :global .device-wrapper {
    width: 200px;
    margin: 0 auto;
    max-width: 50vw;
    video {
      width: 100%;
    }
  }
  :global .screen {
    background: white;
    overflow: hidden;
  }
}
.slider {
  &:global.slick-slider {
    z-index: 0 !important;
  }
}
.slider :global .slick-track {
  display: flex;
  > div:last-child {
    align-self: center;
    h1 {
      text-align: center;
      font-size: 3.5em;
    }
  }
}
.slider :global .slick-dots {
  top: -25px !important;
  button::before {
    color: white !important;
  }
}

.step {
  max-width: 800px;
}
.step:global.step2 h1 {
  text-align: right;
  @media (max-width: 768px) {
    text-align: center;
  }
}
.lastStepfooter {
  position: absolute;
  bottom: 0;
  margin-bottom: 2em;
  width: 100%;
}

.notNow {
  cursor: pointer;
  z-index: 10;

  width: 100%;
  text-align: center;
}

.SelectGroup {
  display: flex;
  cursor: pointer;
  div:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
  div:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-right: 0 !important;
    width: 5em;
  }
  :global {
    .ui.dropdown > .text {
      display: inline-block;
      transition: none;
      margin: auto;
    }
    .ui.selection.dropdown {
      display: flex;
      align-items: center;
      padding: 1em;
      border: 0;
      border-right: 1px solid #ccc !important;
      flex: 1;
      border-radius: 0;
      cursor: pointer !important;
    }
  }
  :global {
    .ui.selection.dropdown > .dropdown.icon {
      display: none;
    }
  }
}
