.Container {
  font-size: 1.3rem;
  padding: 0 0.8em;
  margin: 0;
  list-style: none;

  li {
    padding: 0.3em;
    display: flex;
    align-items: center;
  }
}
:global .hide {
  display: none;
}
.Instruction {
  font-size: 0.9em;
  color: #8e8e8e;
}

.Name {
  text-transform: capitalize;
}
.IngPhrase {
  label {
    font-size: 0.8em;
  }
}
.IngDetails {
  display: flex;
  align-items: center;
}
.IngName {
  flex: 1;
  display: flex;

  flex-direction: column;
}

.removeButton {
  display: flex;
  align-items: center;
  height: 1.5em;
  margin-right: 0.5em;
}
.IngImage {
  display: flex;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
  margin-right: 0.5em;
  justify-content: center;
}
.ingDropdown {
  :global .avatar {
    width: 2em !important;

    border-radius: 0 !important;
  }
}

.Calories {
  font-size: 0.8em;
}

.Quantity,
.Unit {
  font-weight: bold;
  &:global.hidden {
    display: none;
  }
}

// .Ingredient {
//   opacity: 0;
// }

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//     // transform: translateY(20px);
//   }
//   100% {
//     opacity: 1;
//   }
// }

// :global .animated-in {
//   animation: fadeIn 0.2s forwards;
// }
