@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap");
@import "../../variables.scss";

.Container {
  display: flex !important;
  flex-direction: column;
  :global .macrosInjected {
    .ui.green.inverted.segment {
      padding: 0.5em;
    }
  }
}
.Buttons {
  display: flex;
}

:global #root :local .Container {
  width: 100% !important;
  margin: 0 !important;
}
.finalScreen {
  :global .row {
    padding: 4em;
  }
  @media only screen and (max-width: $tabletBreakpoint) {
    :global .row.reversed {
      // flex-direction: column-reverse !important;
    }
    .row {
      padding: 0 !important;
      // margin: 0 -1em !important;
    }
  }
}

.greenHighlight {
  color: $green;
}
.tealHighlight {
  color: #00b5ad;
}
.orangeHighlight {
  color: $orange;
  font-weight: bold;
}

.alternativeHeader {
  font-family: "Manrope", sans-serif !important;
}
.Container {
  background-position-x: 25%;
  background-position-y: -180px;
  background-repeat: no-repeat;
}
.first {
  .textContent {
    display: flex !important;
    .contentContainer {
      margin-top: auto;
    }
  }
  .header:global.ui.large.header {
    font-size: 4em;
  }
  :global .sub.header {
    font-size: 2em;
    line-height: 1.5em;
    margin-bottom: 0.5em;
  }
}

.Container {
  .finalScreen {
    &:global.ui.stackable.grid .row {
      padding: 4em;
      .column {
        > div {
          padding: 4em 4em;
        }
      }
    }
  }
  @media only screen and (max-width: $tabletBreakpoint) {
    &:global.ui.container {
      background-position-x: 40%;
      margin: 0 -1em !important;
      //   :global .blue.segment {
      //     h4 {
      //       margin: 0;
      //       margin-bottom: 4em;
      //     }
      //   }
    }

    .finalScreen {
      &:global.ui.stackable.grid .row {
        padding: 0em;
        .column {
          > div {
            padding: 2em;
          }
        }
        .column {
          padding: 0 !important;
        }
      }
    }

    .phoneCrop {
      width: 100%;
    }
  }
  :global .device-wrapper {
    margin: 0 auto;
    max-width: 50vw;
    video {
      width: 100%;
    }
  }
  :global .screen {
    background: white;
    overflow: hidden;
  }
}

.Planner {
  background: #a3e39d;
  padding: 3em !important;
}
.Planner :global .column h4 {
  margin: 0;
}
.Planner :global .row {
  padding: 0;
}
.Day:global.ui.cards {
  margin: 0.5em 0;
  margin-top: 0;
  .card {
    background: #ffffff30;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    min-height: 2.5em;
    transform: none !important;

    &:global.active {
      background: transparent;
      border: 0;
      box-shadow: none;
      .card {
        background: transparent;
        border: 0;
        box-shadow: none;
      }
    }
    .content {
      padding: 0;
    }
  }
}

.phoneCrop {
  max-height: 300px;
  width: 300px;
  margin: auto;
  padding: 2em 4em 4em;

  overflow: hidden;
  &.yellow {
    background-color: #fce49f;
  }
  &.blue {
    background-color: #30c0f5;
  }
}

.footer {
  background: darken($green, 20%);
  color: white;
  padding: 1em 0.2em !important;
  a {
    color: white;
    &:hover {
      color: $orange;
    }
  }
}

.adjustScreen {
  padding: 6em 10em;
  overflow: hidden;
}

.first.heavyPadding {
  padding: 4em 16% !important;
}

@media only screen and (max-width: $computerBreakpoint) {
  div.heavyPadding {
    padding-top: 2em;
    padding-left: 1em !important;
    padding-right: 1em !important;
    margin: 0 1em;
  }
  .phoneColumn {
    max-height: 240px;
    overflow: hidden;
    width: 100%;
  }
  .Planner {
    :global .ui.three.cards > .card {
      width: calc(33.33333333% - 1em);
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
    :global .ui.cards > .card {
      margin: 0.875em 0.2em;
    }
  }
  .Social {
    font-size: 0.7em;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media only screen and (min-width: $computerBreakpoint) {
  .phoneColumn {
    position: absolute;
    margin-top: -3em;
    margin-right: -3em;
    width: 200px;
  }
}
