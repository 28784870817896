.managerWrapper {
  position: fixed;
  bottom: 50px;

  border-radius: 2em;
  z-index: 1000;
  animation: grow 0.5s ease-in forwards;
  box-sizing: border-box;
  margin-left: -0.5em;
  .managerContent {
    padding: 1.5em 0em;
  }
  .managerHeader {
    position: absolute;
    width: 200px;
    cursor: pointer;
  }
  .managerActions {
    margin-top: 30px;
    cursor: pointer;
    background: #ffce02;
    padding: 1em;
    border-radius: 0.5em;
    max-width: 88px;
  }
}

@keyframes grow {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 550px;
  }
}

.tagWrapper {
  border-top-right-radius: 3em;
  border-bottom-right-radius: 3em;
  transition: all 0.5s;
  border-top-left-radius: 3em;
  border-bottom-left-radius: 3em;
  display: flex;
  margin-bottom: 1em;

  .tagContainer {
    width: 0;
    overflow: hidden;
    transition: all 0.5s;

    h4 {
      width: 250px;

      margin: 0.2em 0;
    }
  }
  &.open {
    box-shadow: #0000007d 2px 2px 2px 0px;
    background: #ffffff;
    transition: all 0.5s;
    .tagContainer {
      width: 100%;
    }
    width: 90vw;
    max-width: 340px;

    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  .tags {
    height: 100px;
    width: 100%;
    padding-right: 0.5em;
    overflow-y: scroll;
  }
}
