@import url("https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;700;900&display=swap");
a {
  cursor: pointer;
}
body {
  background: #ffffff;
  font-family: "Baloo Da 2", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.secondaryFont {
  font-family: "Poppins", sans-serif !important;
}

#root {
  display: flex;
  min-height: 100vh;
  justify-content: space-around;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.MuiDialog-root {
  position: fixed;
  z-index: 10000 !important;
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }
  html,
  body {
    width: 210mm !important;
    height: 297mm !important;
  }
}
