.addButton {
  position: fixed;
  z-index: 25;
  bottom: 120px;
  right: 6vh;
  > div {
    background-color: #ffce07 !important;
    color: #47c73c !important;
    height: 50px;
    width: 50px;
    border-radius: 50% !important;
    display: flex !important;
    > i {
      display: flex !important;
      flex: 1 !important;
      justify-content: center;
    }
  }
}
