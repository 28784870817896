.Button {
  cursor: pointer;
  transition: background-color 0s ease;
  background-color: transparent !important;
  padding: "0.3em 0.4em";
}
.ui.label.tagLabel {
  padding: 0.3em 0.4em;
  background: transparent !important;
  border-radius: 6px !important;
  border: 1px solid #47c73c !important;
  margin: 0;
  margin-right: 0.2em;
  margin-bottom: 0.3em;
  color: #47c73c !important;

  .tagDetail {
    margin: 0 !important;
    text-transform: capitalize;
    font-size: 1em;
    background: transparent;
  }
  &.button {
    &.ui.label.tagLabel .tagDetail {
      font-size: 1.2em !important;
    }

    @media (hover: hover) {
      &:active {
        background: #47c73c !important;
        color: white !important;
      }
    }

    &.active {
      background: #47c73c !important;
      color: white !important;
    }
  }
}
