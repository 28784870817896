.simpleUploadContainer {
  position: relative;
  :global .label {
    cursor: pointer;
    user-select: none;
  }
}
.uploadedFile {
  position: relative;
  &:global.hidden {
    display: none;
  }
  img {
    max-width: 200px;
    max-height: 200px;
  }
}
.buttons {
  display: flex;
}
