@import "../../variables.scss";

.Image {
  margin: 0 -1em;
  background: #ccc;
  height: 180px;
  background-size: cover !important;
}

.ImageUpload :global .imagePreview {
  height: 100%;
  width: 100%;
  position: absolute;
}
.ImageUpload :global .uploadPicturesWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.CreateMealFullScreen {
  display: block;
  height: 100%;
}

.ImageUpload {
  height: 160px;
}
.ImageUpload :global .fileContainer {
  box-shadow: none !important;
  background: #ffffffc9;
  margin: 0 !important;
  padding: 1em;
  text-align: center;
}
.ImageUpload :global .uploadPictureContainer {
  .deleteImage {
    visibility: hidden;
    position: absolute;
    top: 4px;
    right: 4px;
    color: #fff;
    background: #ff4081;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    width: 20px;
    height: 20px;
  }
  button {
    font-family: inherit;
  }

  width: 100% !important;
  overflow: hidden;
  margin: 0 !important;
  z-index: 0;
  background-size: cover;
}

.ImageUpload:global.fileUploader {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  margin-bottom: 0.5em;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  // &.inline {
  //   height: auto;
  // }
}

:global .fileContainer.inline {
  box-shadow: none;
  padding: 0;
  align-items: normal;
  .attached.button {
    text-align: center !important;
  }
  .inlineButton {
    padding: 10px;
    color: $green;
    font-size: 1.1em !important;
  }
}

.Title {
  margin: 0.5em 0 !important;
}

.Content {
  margin-top: 0 !important;
}

.recipeMeta {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  li {
    flex: 1;
    padding: 0.5em 0;
    color: #979797;
    font-weight: bold;
    padding: 0.5em;
    label {
      margin-right: 1em;
    }
  }
}

.originalIngredients {
  user-select: none;
  h4 {
    cursor: pointer;
  }

  .originalIngs {
    display: none;
    font-size: 1.2em;
    line-height: 2em;
  }
  &:global.active {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 1em;
    border: solid 1px #ccc;
    border-radius: 2em;
    :local .originalIngs {
      display: block !important;
    }
  }
}

.header {
  display: flex;
  padding: 0.5em 0;
  h1 {
    font-size: 1.5rem !important;
  }
  .title {
    flex: 1;
  }
  .editButton {
    font-size: 1.3em;
    flex: 0;
    display: flex;
    align-items: center;
    text-align: right;
    padding-left: 0.4em;
    align-items: flex-start;
    svg {
      margin-left: auto;
    }
  }
  .saveButton {
    flex: 0;
    display: flex;
  }
  h1 {
    margin: 0;
  }
}
.recipeText textarea {
  line-height: 1.7em !important;
}
.TabsOverride :global {
  @media print {
    .ui.secondary.menu {
      display: none !important;
    }
  }
  @media (max-width: 768px) {
    margin: 0 -1rem;
    margin-top: -1.5rem;
  }
  @media (min-width: 768px) {
    .ui.segment.tab {
      padding: 0 !important ;
    }
  }

  .ui.segment.tab {
    font-size: 1em;
    position: relative;
    background: transparent;
    box-shadow: none;
    margin: 1rem 0em;
    border-radius: 0;
    border: none;
    padding: 0 1em;
  }
  .ui.secondary.menu {
    display: flex;

    .item {
      flex: 1;
      font-size: 1rem;
      margin: 0;
      &.active {
        font-weight: 600;
        border-radius: 0;
      }
    }
  }
}

:global .recipeView {
  .ui.segment {
    background: white;
    @media only screen and (max-width: $tabletBreakpoint) {
      > .grid {
        margin-left: -1em !important;
        margin-right: -1em !important;
        background: white;
      }
    }
  }
}

.recipeHomeMenu {
  background: white;
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  border-bottom: 1px #ccc solid;
  font-size: 1.5em;
  user-select: none;
  cursor: pointer;

  a {
    flex: 1;
    text-align: center;
    padding: 1em;
    &:hover {
      background: #f1f1f1;
    }
    &:first-child {
      border-right: 1px #ccc solid;
    }

    color: black;

    &:global.active {
      font-weight: bold;
    }
  }
}

.File {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.Categories {
  :global .ui.button {
    text-transform: capitalize !important;
    flex: 1 1 10em;
    text-transform: capitalize !important;
    margin: 0.2em;
  }
}

.Tabs {
  margin: 0 -1em;

  ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    border: 1px solid #d8d8d8;
    li {
      flex: 1 0;
      padding: 0.9em;
      font-size: 1.3rem;
      border-left: 1px solid #d8d8d8;
      font-weight: normal;
      text-align: center;
      user-select: none;
      cursor: pointer;
    }
    li.active,
    li:hover {
      font-weight: 600;
    }
    li:first-child {
      border-left: 0;
    }
  }
}

.addButton {
  position: fixed;
  z-index: 25;
  bottom: 120px;
  right: 6vh;
  > div {
    background-color: #ffce07 !important;
    color: #47c73c !important;
    height: 50px;
    width: 50px;
    border-radius: 50% !important;
    display: flex !important;
    > i {
      display: flex !important;
      flex: 1 !important;
      justify-content: center;
    }
  }
}

@keyframes keyframes1 {
  0% {
    transform: rotate(-1deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(1.5deg);
    animation-timing-function: ease-out;
  }
}

@keyframes keyframes2 {
  0% {
    transform: rotate(1deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(-1.5deg);
    animation-timing-function: ease-out;
  }
}

:global .wiggle > div:nth-child(2n) {
  animation-name: keyframes1;
  animation-iteration-count: infinite;
  transform-origin: 50% 10%;
}
:global .wiggle > div:nth-child(2n-1) {
  animation-name: keyframes2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: 30% 5%;
}
