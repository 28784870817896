.Container {
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ol {
    counter-reset: li; /* Initiate a counter */
    margin-left: 0; /* Remove the default left margin */
    padding-left: 0; /* Remove the default left padding */
  }
  ol > li {
    position: relative; /* Create a positioning context */
    margin: 1em 0 6px 2em; /* Give each list item a left margin to make room for the numbers */
    padding: 4px 8px; /* Add some spacing around the content */
    list-style: none; /* Disable the normal item numbering */
    // border-top: 2px solid #666;
    // background: #f6f6f6;
  }
  ol > li:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    top: -2px;
    left: -2em;
    box-sizing: border-box;
    width: 2em;
    margin-right: 8px;
    /* padding: 4px; */
    /* border-top: 2px solid #666; */
    color: #000;
    background: #eaeaeb;
    font-weight: 300;
    text-align: center;
    border-radius: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    /* justify-items: center; */
    text-align: center;
    justify-content: center;
  }
  li ol,
  li ul {
    margin-top: 6px;
  }
  ol ol li:last-child {
    margin-bottom: 0;
  }
}
