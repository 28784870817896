@import "../../variables.scss";

.CustomCard :global .ui.card {
  height: 7em;
  overflow: hidden;
  position: relative;
  margin: 0;
  width: auto;
  :global .label {
    padding: 0.25em 1em;
  }
  :local .cycleName {
    border-top-right-radius: 0px !important;
  }
  :local .workoutName {
    border-bottom-right-radius: 0px !important;
  }
}

@media (min-width: 768px) {
  .CustomCard :global .ui.card {
    height: 12em;
  }
}

.CardIcon {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0 !important;
  display: flex;
  &:global.treat {
    background-color: $treat;
    color: darken($treat, 60%);
  }
  &:global.eatout {
    background-color: $eatout;
    color: darken($eatout, 60%);
  }
  &:global.custom {
    background-color: $custom;
    color: darken($custom, 60%);
  }
  .Icon {
    font-size: 4rem;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .Caption {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.SelectedImage {
  width: 100%;
  height: 100%;
  background-color: #000000ab;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  opacity: 0.9;
}

.editCard {
  position: absolute;
  top: 5px;
  right: 10px;
}
