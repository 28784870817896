:global .number-picker-group {
  display: flex;
}
.Container {
  position: relative;
  font-size: 2em;
  margin: 0 0.5em;
  display: flex;
  flex: 1;
}
.selectOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.selectOverlay:focus + .custom {
  outline: 2px solid #32a1ce;
}
.selectOverlay:hover + .custom {
  border: 1px solid #9a9a9a;
}

/* 
    Add spacing between all of the children 
    If you don't know how this works, check out the lobotomized owl selector:
    https://pineco.de/get-to-know-the-lobotomized-owl-selector/
  */
.custom * + * {
  margin-left: 7px;
}

.custom {
  padding: 0.5em 0.2em;
  border: solid white 1px;
  border-radius: 8px;

  display: inline-block;
  text-align: center;
  flex: 1;
  flex-grow: 1;
}
