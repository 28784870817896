@import "../../variables.scss";

.Avatars {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;

  li {
    padding: 1em;
    flex: 0 0 10em;
    height: 10em;
    width: 10em;
    &:global.active {
      img,
      > div {
        border: 4px solid $green;
        border-radius: 5em;
      }
    }
  }
}

.userUpload {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px dashed black;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  overflow: hidden;
  background-size: cover;
}
