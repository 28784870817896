@import "../../variables.scss";

@media print {
  /* All your print styles go here */
  .shoppingSegment {
    border: 0 !important;
    box-shadow: none !important;
    margin: 0 4em !important;
  }
}

.GroupTitle {
  font-family: $alternativeFont;

  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  letter-spacing: 2.83333px;
  text-transform: uppercase;

  color: #222222;
  margin: 0;
  margin-top: 2em !important;
  position: relative;
}
.Group {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0.8em 0.2em;
    border-bottom: 1px solid #ccc;
    text-transform: capitalize;
    .shoppingTopRow {
      display: flex;
    }
  }
}
.collapse {
  right: 0;
  position: absolute;
  margin-right: 0.5em;
}
.QuantityInput > :global.label {
  padding-right: 1.5em !important;
}
.shoppingRecipeName {
  margin-left: 2em;
  margin-top: 5px;
  span {
    text-transform: lowercase;
  }
}
.RecipeTitle {
  text-transform: capitalize;
}
.shoppingItem {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.Ingredient {
  font-size: 1.4em;
}
.IngredientAmount {
  margin-left: 1em;
  font-size: 0.9em;
  font-weight: bold;
  color: #909090;
}
.IngredientUnit {
  color: #909090;
  font-size: 0.9em;
  font-weight: bold;
  margin-left: 0.2em;
  text-transform: lowercase;
}

.tickBox {
  display: inline-block;
  margin-right: 1em;

  :global .round {
    position: relative;
  }

  :global .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
    margin-top: -2px;
  }

  :global .round.indeterminate label:after {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: none;
    width: 10px;
    border-left: none;
  }
  :global .round input[type="checkbox"]:checked + label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 12px;
  }
  :global .round input[type="checkbox"] {
    visibility: hidden;
  }

  :global .round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  :global .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
  :global .round.indeterminate input[type="checkbox"] + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  :global .round.indeterminate input[type="checkbox"] + label:after {
    opacity: 1;
  }
}
:global .checked,
:global .sectionComplete {
  display: none;
}

.sortDropdown :global .dropdown {
  text-transform: capitalize;
}

.shoppingListRow {
  user-select: none;
  cursor: pointer;
  &.purchased {
    background: #eaffeb;
  }
}

.totalCount {
  color: $green;

  margin-left: 0.5em;
}

.adjustModal :global .content {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;

  font-size: 1.2em !important;
  .column {
    display: flex;
    align-items: center;
  }
}
.QuantityInput input {
  text-align: center !important;
}
.QuantityInput p {
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
}
.stockResult {
  margin-top: 2em;
  > p {
    text-align: center;
    font-weight: 600;
    margin-bottom: 0;
  }
}
.stockTotal {
  :global .ui.basic.label {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
  }
  :global .ui.label {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
  }
  input {
    max-width: 9em !important;
  }
}
// .listGroup {
//   margin: 0;
//   padding: 1em;
//   :global .column {
//     padding: 0 1em !important;
//   }
// }
.shoppingBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.adjustGroupInput {
  display: flex;
  input {
    max-width: 5em !important;
    border-radius: 0 !important;
  }
  :global .ui.buttons .button:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 00;
  }
}
.shoppingCount {
  background: #47c73c;
  border-radius: 16px;
  color: white;
  padding: 0.5em 1.5em;
}
.additional {
  margin-top: -0.1em;

  margin-right: -1em;
}

.purchasedGroup {
  width: 100%;
  .subAisle {
    font-size: 0.8em;
    margin-top: 0.8em;
  }
  .GroupTitle {
    margin-bottom: 0.5em;
  }
  h5 {
    margin-top: 0.5em;
    margin-bottom: -0.3em;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }
}

:global .my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
:global .my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
:global .my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
}

.shoppingSegment {
  font-size: 0.8em !important;
}
